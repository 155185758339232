import { Suspense } from "react";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { Home, PrivacyPolicy, Referral } from "./Pages";
import { Loader } from "./Components/UI";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import store, { persistor } from "./Redux/Store";
import { Toaster } from "react-hot-toast";

const router = createBrowserRouter([
  {
    path: "/",
    element: <Home />,
  },

  {
    path: "/referral",
    element: <Referral />,
  },
  {
    path: "/privacy-policy",
    element: <PrivacyPolicy />,
  },
]);

function App() {
  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <Toaster position="top-right" />

        <Suspense
          fallback={
            <>
              <Loader />
            </>
          }
        >
          <RouterProvider router={router} />
        </Suspense>
      </PersistGate>
    </Provider>
  );
}

export default App;
