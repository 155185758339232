/* eslint-disable jsx-a11y/anchor-is-valid */
import { Col, Container, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import logo from "../../../Assets/Icon/logo.svg";
import facebook from "../../../Assets/Icon/facebook.svg";
import telegram from "../../../Assets/Icon/telegram.svg";

import "./Footer.scss";

const Footer = () => {
  return (
    <footer className="footer">
      <Container>
        <Row>
          <Col lg={6} sm={6} xs={12}>
            <div className="footer_in">
              <div className="logo">
                <img src={logo} alt="footer-logo" />
              </div>
              <p>
                Black Tie Asset Hub is at the forefront of transforming the real
                estate industry through the innovative use of blockchain
                technology.
              </p>
            </div>
          </Col>
          <Col lg={4} sm={6} xs={6}>
            <ul className="footer-links">
              <li>
                <h6>Independent Remittance Dealer</h6>
                <a>IND100778824-001</a>
              </li>
              <li>
                <h6>Exchange Registered Provider</h6>
                <a>DCE100778824-001</a>
              </li>
            </ul>
          </Col>
          <Col lg={2} sm={6} xs={6}>
            <ul className="footer-links">
              <li>
                <h6>Security</h6>
                <Link to="/privacy-policy">Privacy Policy</Link>
              </li>
            </ul>
          </Col>
        </Row>
      </Container>
      <div className="copyright">
        <Container>
          <p>
            © {new Date().getFullYear()}. All Rights Reserved Black Tie Holdings
            Pty Ltd
          </p>
          <ul className="social-links">
            <li>
              <a href="#" target="_blank">
                <img src={facebook} alt="facebook-icon" />
              </a>
            </li>
            <li>
              <a href="#" target="_blank">
                <img src={telegram} alt="telegram-icon" />
              </a>
            </li>
          </ul>
        </Container>
      </div>
    </footer>
  );
};

export default Footer;
